<template scoped>
  <div>
    <el-form-item
      :rules="rules.execute"
      label="Execute"
      prop="execute.type"
      :style="{ marginTop: '10px' }"
    >
      <el-select
        @change="type_change"
        v-model="logic.execute.type"
        :style="{ width: '100%' }"
        placeholder="Select the Execute"
      >
        <el-option
          v-for="item in supportExecute"
          :key="item.value"
          :label="item.name"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <component
      :is="content"
      :logic="logic"
      :endpoint="endpoint"
      :productId="productId"
    ></component>
  </div>
</template>

<script>
export default {
  props: {
    logic: Object,
    endpoint: String,
    productId: String,
  },
  computed: {},
  data: function () {
    return {
      content: null,
      supportExecute: [
        {
          name: "Flow",
          value: "Flow",
        },
        {
          name: "Sleep",
          value: "Sleep",
        },
        {
          name: "Flow Logic",
          value: "FlowLogic",
        },
        {
          name: "End",
          value: "End",
        },
        {
          name: "Webhook",
          value: "Webhook",
        },
      ],
      rules: {
        execute: [{ validator: this.validator_execute, trigger: "blur" }],
      },
    };
  },
  methods: {
    validator_execute: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the execute should not be empty"));
      } else {
        callback();
      }
    },
    type_change: function (val) {
      switch (val) {
        case "Flow":
          this.content = () => import("./Flow/Panel");
          break;
        case "Sleep":
          this.content = () => import("./Sleep/Panel");
          break;
        case "FlowLogic":
          this.content = () => import("./FlowLogic/Panel");
          break;
        case "End":
          this.content = () => import("./End/Panel");
          break;
        default:
          this.content = null;
          break;
      }
    },
  },
  created: function () {
    if (this.logic.execute.type) {
      this.type_change(this.logic.execute.type);
    }
  },
};
</script>